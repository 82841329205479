import { createRoot } from 'react-dom/client';

// Import global styles
import 'assets/scss/global.scss';

import { init } from 'services/Translation';

import 'services/Error/init';

/**
 * Initialize i18next instance (which maybe take time to download JSON files) before we start using makeTranslationMap
 * so that we can get Japanese translations correctly.Therefore, src/mappers and src/domains are imported in App component.
 */
init().then(() => {
    import('./App').then(async ({ default: App }) => {
        // Unregister service worker to remove old files loaded from the cache storage
        // Service worker are not supported by all browsers so in this case the service
        // worker will be undefined, so we need the coalesce check, specially on
        // mobile browser
        navigator?.serviceWorker?.getRegistration().then((registration) => {
            if (registration) {
                console.log('Service worker is already installed');

                // Unregister service worker
                navigator.serviceWorker.getRegistrations().then(function (registrations) {
                    for (let registration of registrations) {
                        registration.unregister();
                    }
                });

                // Remove service worker cache in CacheStorage
                caches.keys().then(function (keys) {
                    let promises = [];
                    keys.forEach(function (cacheName) {
                        if (cacheName) {
                            promises.push(caches.delete(cacheName));
                        }
                    });
                });
            }
        });

        const container = document.getElementById('app');
        const root = createRoot(container!);
        root.render(<App />);
    });
});
