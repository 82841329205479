import { EventHint, init, rewriteFramesIntegration, ErrorEvent } from '@sentry/react';

const shouldUseSentry = import.meta.env.VITE_HOST_ENV === 'production' || import.meta.env.VITE_HOST_ENV === 'staging';

if (shouldUseSentry) {
    init({
        environment: import.meta.env.VITE_HOST_ENV,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: import.meta.env.VITE_VERSION,
        integrations: function (integrations: any) {
            // allowing capture multiples times with same error.
            const customIntegrations = integrations.filter(function (integration: any) {
                return integration.name !== 'Dedupe';
            });

            customIntegrations.push(rewriteFramesIntegration());

            return customIntegrations;
        },
        beforeSend: function beforeSend(event: ErrorEvent, hint: EventHint) {
            try {
                const err = hint.originalException;

                // To ignore "Failed to fetch dynamically imported module" error
                if (err instanceof Error && err?.message?.match(/Failed to fetch dynamically imported module/)) {
                    return null;
                }

                const exception = (event || {}).exception || {};

                // To avoid extraneous Sentry errors (401, etc...) we
                // prevent Sentry from capturing unhandled exceptions
                // @ts-ignore
                if (exception?.mechanism && exception?.mechanism.type === 'onunhandledrejection') {
                    return null;
                }

                // CKEditor throws a lot of errors, 99% of them are unavoidable, 99% of those are unfixable
                // So, if the latest frame from the stacktrace is from CKEditor skip sending it to Sentry
                const stacktraceFrames =
                    exception.values &&
                    exception.values[0] &&
                    exception.values[0].stacktrace &&
                    exception.values[0].stacktrace.frames;
                const lastFrame = stacktraceFrames && stacktraceFrames[stacktraceFrames.length - 1];

                if (lastFrame?.filename && lastFrame.filename.indexOf('ckeditor.js') > 0) {
                    return null;
                }
            } catch (err) {}

            return event;
        },
    });
}
